<template>
    <div class="flex flex-col flex-grow p-6 bg-dark-1 overflow-y-auto">
        <OrganizationsMenu/>
        <div class="mt-5 flex-grow flex flex-col">
            <nav class="flex-1 px-2 bg-dark-1" aria-label="Sidebar">
                <template v-for="item in navigation" :key="item.name">
                    <div v-if="!item.children" class="my-6">
                        <router-link :to="item.href"
                                     :class="[route.path === item.href ? 'text-white' : ' text-default-3', 'bg-dark-1 flex flex-row items-center text-body-6']">
                            <component :is="item.icon" :color="$route.path === item.href  ? colorActive : colorDefault" class="mr-2.5 h-3.5 w-3.5"
                                       aria-hidden="true" />
                            {{ item.name }}
                        </router-link>
                    </div>
                    <Disclosure as="div" v-else class=" my-6" v-slot="{ open }">
                        <DisclosureButton
                            :class="[$route.path === item.href ? 'text-white' : 'text-default-3', ' group w-full flex items-center text-left text-sm font-normal focus:outline-none ']">
                            <component :is="item.icon" :color="$route.path === item.href  ? colorActive : colorDefault" class="mr-2.5 h-3.5 w-3.5"
                                       aria-hidden="true" />
                            <span class="flex-1">
                {{ item.name }}
              </span>
                            <img v-if="open" src="../../assets/icons/chevron-up.svg" alt="close" />
                            <img v-else src="../../assets/icons/chevron-down.svg" alt="open"/>
                        </DisclosureButton>
                        <DisclosurePanel class="">
                            <router-link v-for="subItem in item.children" :key="subItem.name" :to="subItem.href"
                                         :class="[$route.path === subItem.href  ? 'text-white' : 'text-default-3', ' group w-full flex items-center text-left text-sm font-normal focus:outline-none ml-6 my-4']">
                                {{ subItem.name }}
                            </router-link>
                        </DisclosurePanel>
                    </Disclosure>
                    <hr v-if="item.hasSeparator" class="h-px bg-background-3 opacity-20 mt-6 mb-6" />
                </template>
            </nav>
        </div>
        <div class="flex mt-9">
            <img class="h-8" src="@/assets/icons/logo/white/horizontal_powered_by.svg" alt="Workflow" />
        </div>
    </div>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import ChatIcon from '../icons/sidebar/Chat.vue'
import HomeIcon from '../icons/sidebar/Home.vue'
import MailIcon from '../icons/sidebar/Mail.vue'
import UsersIcon from '../icons/sidebar/Users.vue'
import BellIcon from '../icons/sidebar/Bell.vue'
import FolderIcon from '../icons/sidebar/Folder.vue'
import DocumentIcon from '../icons/sidebar/Document.vue'
import {useAuth} from "@/stores/AuthStore";
import {storeToRefs} from "pinia";
import {computed, onBeforeMount, watch} from "vue";
import {useRoute} from "vue-router";
import OrganizationsMenu from "@/components/menus/Organizations.vue";
import ReceiptIcon from "@/components/icons/sidebar/Receipt.vue";
import {useCheckFeature} from "@/composables/useCheckFeature";

const {loadMemberFeatures} = useCheckFeature();
const {memberFeatures} = useCheckFeature();

const colorActive = '#ffffff';
const colorDefault = '#A6B0CF';

const authStore = useAuth();
const {isAdmin, isContentAdmin, isRepresentative, member, organization} = storeToRefs(authStore);

const route = useRoute();

const navigationAdmin = [
    {
        name: 'Dashboard',
        icon: HomeIcon,
        current: false,
        href: '/'
    },
    {
        name: 'News',
        icon: ReceiptIcon,
        current: false,
        href: '/news'
    },
    {
        name: 'Email & SMS',
        icon: MailIcon,
        current: false,
        href: '/inbox',
    },
    {
        name: 'Members',
        icon: UsersIcon,
        current: false,
        children: [
            { name: 'Members List', href: '/members' },
            { name: 'Groups', href: '/groups'},
            { name: 'Profile builder', href: '/profile-builder' },
            { name: 'Import and invite', href: '/import' },
        ],
    },
    {
        name: 'Resources',
        icon: FolderIcon,
        current: false,
        href: '/resources/view',
    },
    {
        name: 'Documents',
        icon: DocumentIcon,
        current: false,
        href: '/documents',
        hasSeparator: true
    },
    {
        name: 'System Notifications',
        icon: BellIcon,
        current: false,
        href: '/notifications'
    },
];

const navigationContentAdmin = [
    {
        name: 'Dashboard',
        icon: HomeIcon,
        current: false,
        href: '/'
    },
    {
        name: 'News',
        icon: ReceiptIcon,
        current: false,
        href: '/news'
    },
    {
        name: 'Email & SMS',
        icon: MailIcon,
        current: false,
        href: '/inbox',
    },
    {
        name: 'Resources',
        icon: FolderIcon,
        current: false,
        href: '/resources/view',
    },
    {
        name: 'Documents',
        icon: DocumentIcon,
        current: false,
        href: '/documents',
        hasSeparator: true
    },
    {
        name: 'System Notifications',
        icon: BellIcon,
        current: false,
        href: '/notifications'
    },
];


const navigationRepresentative = [
    {
        name: 'Dashboard',
        icon: HomeIcon,
        current: false,
        href: '/'
    },
    {
        name: 'News',
        icon: ReceiptIcon,
        current: false,
        href: '/news'
    },
    {
        name: 'Email & SMS',
        icon: MailIcon,
        current: false,
        href: '/inbox',
    },
    {
        name: 'Members',
        icon: UsersIcon,
        current: false,
        children: [
            { name: 'Members List', href: '/members' },
            { name: 'Groups', href: '/groups'},
        ],
    },
    {
        name: 'Resources',
        icon: FolderIcon,
        current: false,
        href: '/resources/view',
    },
    {
        name: 'Documents',
        icon: DocumentIcon,
        current: false,
        href: '/documents',
        hasSeparator: true
    },
    {
        name: 'System Notifications',
        icon: BellIcon,
        current: false,
        href: '/notifications'
    },
];

const navigationRestricted = [
    {
        name: 'Profile',
        icon: UsersIcon,
        current: false,
        href: '/profile'
    },
];

const navigationMember = [
    {
        name: 'Dashboard',
        icon: HomeIcon,
        current: false,
        href: '/'
    },
    {
        name: 'News',
        icon: ReceiptIcon,
        current: false,
        href: '/news'
    },
    {
        name: 'Email & SMS',
        icon: MailIcon,
        current: false,
        href: '/inbox',
    },
    {
        name: 'Resources',
        icon: FolderIcon,
        current: false,
        href: '/resources/view',
    },
    {
        name: 'Documents',
        icon: DocumentIcon,
        current: false,
        href: '/documents',
        hasSeparator: true
    },
    {
        name: 'System Notifications',
        icon: BellIcon,
        current: false,
        href: '/notifications'
    },

];

const features = [{
    name: 'Chat',
    icon: ChatIcon,
    current: false,
    href: '/chat'
}]

const navigation = computed(() => {
    let nav = [];

    if (isAdmin.value) {
        nav = [...navigationAdmin];
    } else if (isRepresentative.value) {
        nav = [...navigationRepresentative];
    }else if (isContentAdmin.value) {
        nav = [...navigationContentAdmin];
    } else {
        if (member.value.status === 'approved') {
            nav = [...navigationMember];
        } else {
            nav = [...navigationRestricted];
        }
    }

    if (memberFeatures.value) {
        const featureNav = [];
        Object.keys(memberFeatures.value).forEach((feature) => {
            if (memberFeatures.value[feature]) {
                const aux = features.find(f => f.name.toLowerCase() === feature);
                if (aux) {
                    featureNav.push(aux);
                }
            }
        });

        nav = [...nav.slice(0, 2), ...featureNav, ...nav.slice(2)];
    }

    return nav;
});

onBeforeMount(() => {
    loadMemberFeatures(organization.value.slug);
})

watch(() => route.path, (newRoute, oldRoute) => {
    if (newRoute !== oldRoute) {
        loadMemberFeatures(organization.value.slug);
    }
});
</script>
